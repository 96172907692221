* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Source Code Pro", monospace;
  }
  .home,
  .projects,
  .experience,
  .contactMe {
    left: 150px;
    width: calc(100vw - 150px);
    position:relative;
    display: flex;
    font-size: 3rem;
  }