:root{
    --primary:#fff;
}

.btn{
    padding:8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary{
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
    font-weight: bold;
    display: inline-flex;
}

.btn--outline{
    background: transparent;
    color: rgb(0, 247, 255);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    border-color: rgb(0, 247, 255);
    transition: all 0.3s ease-out;
    font-weight: bold;
    display: inline-flex;
} 

.btn--medium{
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large{
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover{
    color: rgb(37, 37, 37);
    border-color: rgb(37, 37, 37);
    background:rgb(0, 247, 255);
    background-position: 0 0;
}