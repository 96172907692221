.projects-container{
    left: 150px;
    width: fit-content;
    position: relative;
}

.projects-text{
    color: white;
    float:left;
    margin-top: 100px;
    font-size: min(100px, 12vw);
    font-family: "Source Code Pro", monospace;
}