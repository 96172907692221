body {
    background-color: rgb(20, 20, 20);
}

.hero-container {
    top: 31vh;
    left:150px;
    height: 100vh;
    width: fit-content;
    position: relative;
}

.hero-left{
    position: relative;
    float:left;
}

.hero-text {
    color: white;
    margin: 0;
    float:left;
    font-size: 6.2rem;
    font-family: "Source Code Pro", monospace;
}

.hero-img{
    height: 50vh;
    width: auto;
    border-radius: 50%;
    border: 10px double rgb(0, 247, 255);
    z-index: 10;
}

.hero-job {
    float: left;
    width: 50vw;
    color:rgb(0, 247, 255);
    text-shadow: 3px -3px rgb(0, 153, 255), 6px -6px rgb(0, 38, 255);
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-family: "Source Code Pro", monospace;
} 

.hero-btns{
    position:relative;
}